import React from "react";

function Checkbox({ onChange, label, inputRef, name, style, checked = false }) {
  if (typeof checked === "object") {
    checked = checked[0];
  }
  return (
    <div className="checkbox-wrapper" style={style} onClick={() => onChange(!checked)}>
      <input
        className="input checkbox-input"
        type="checkbox"
        name={name}
        ref={inputRef}
        onChange={() => {}}
        checked={checked}
      />
      <label className="label checkbox-label" dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
}

export {
  Checkbox
};
