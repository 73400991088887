import React from "react";
import logo from "../../images/wint.png";
import ModalInfoButton from "../ModalInfoButton";
import applicationTexts from "../../applicationTexts.json";

export default function WintComponent({
  register,
  errors,
  submit,
  children,
  touched,
}) {
  const handleClick = async (e) => {
    e.preventDefault();
    submit();
  };
  return (
    <div className="container erp-container">
      <div className="erp-header">
      <img className="erp-logo" src={logo} alt="logo" />
        <ModalInfoButton
          modalBody={
            <span
              dangerouslySetInnerHTML={{
                __html: applicationTexts.wintInformationModalText,
              }}
            />
          }
        >
          i
        </ModalInfoButton>
      </div>
      <div className="input-container erp-input-container">
      {errors.companyId &&
        <div className="message error-message">
          {errors.companyId.message}
        </div>
        }
        <label className="label erp-input-label">Användarnamn</label>
        <input
          className="input erp-input"
          type="text"
          name="companyId"
          placeholder="Användarnamn"
          isInvalid={errors.companyId}
          isValid={touched.companyId && !errors.companyId}
          ref={register({
            required: applicationTexts.mandatoryField,
          })}
        />
        {errors.apiKey &&
        <div className="message error-message">
          {errors.apiKey.message}
        </div>
        }
        <label className="label erp-input-label">API-nyckel</label>
        <input
          className="input erp-input"
          type="text"
          name="apiKey"
          placeholder="API-nyckel"
          isInvalid={errors.apiKey}
          isValid={touched.apiKey && !errors.apiKey}
          ref={register({
            required: applicationTexts.mandatoryField,
          })}
        />
        {children}
        <button className="button erp-submit-button" type="submit" onClick={handleClick}>
          {applicationTexts.connectCompanyToErpButtonText}
          <div className="button-icon"></div>
        </button>
      </div>
    </div>
  );
}
