import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import Footer from "../components/Footer";
import settings from "../settings/index";
import applicationTexts from "../applicationTexts.json";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import * as QRCode from "qrcode";

export default function AuthenticationView({ setAuthenticationInformation }) {
  const { register, handleSubmit, errors } = useForm();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [animatedQrCode, setAnimatedQrCode] = useState("");
  const [cancelRef, setCancelRef] = useState({});
  const [cancelStatusOk, setCancelStatusOk] = useState(false);
  const showPersonalNumberInput = settings.bankId.showPersonalNumberInput;
  const [loginOnSameDevice, setLoginOnSameDevice] = useState(false);
  const opts = {
    errorCorrectionLevel: 'L',
    type: 'image/png',
    quality: 0.3,
    margin: 1,
    color: {
      dark:"#000000FF",
      light:"#FFFFFFFF"
    }
  }

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || (/iPhone/.test(userAgent) && !window.MSStream)) {
      setLoginOnSameDevice(true);
    }
  }, []);

  const saveCancelRef = (authObj) => {
    if (authObj.orderRef) {
      setCancelRef(authObj.orderRef);
    }
  } 


  const qrCode = (authObj) => {
    if (authObj.orderRef) {
      setCancelRef(authObj.orderRef);
    }
    if (authObj.status !== "pending" || loginOnSameDevice) {
      return;
    }
    const code = authObj.animatedQrCode;
    QRCode.toDataURL(code, opts, function (err, url) {
      if (err) { 
        throw err
      }
      setAnimatedQrCode(url);
    });
  } 

  const getPersonalNumber = (personalNumber) => {
    if(personalNumber == null) {
      return null;
    }
    const _personalNumber = personalNumber.replace("-", "");
    if (_personalNumber.length === 10) {
      let prefix = "19";
      if (
        parseInt(_personalNumber.substr(0, 2), 10) <
        new Date().getFullYear() % 100
      ) {
        // If no prefix is given, assume the person is younger than 100
        prefix = "20";
      }
      return prefix + _personalNumber;
    }
    return _personalNumber;
  };

  const cancelLogin = async (ref) => {
    const res = await Axios.delete(
      `${settings.apiBase}/api/authentication/user/token/${ref}`
    );
    if (res && res.status && res.status === 200) {
      setCancelStatusOk(true);
    }

  }


  const onSubmit = async (formData) => {
    setCancelStatusOk(false);
    let personalNumber = getPersonalNumber(formData.personalNumber);
    if (personalNumber === undefined || personalNumber === "") {
        personalNumber = null;
    }
    setIsAuthenticating(true);
    try {
      const initialUserAuthResponse = await Axios.post(
        `${settings.apiBase}/api/authentication/user/token`,
        {
          personalNumber: personalNumber, 
        }
      );
      const { orderRef, autoStartToken } = initialUserAuthResponse.data;
      if (loginOnSameDevice) {
        // On Android 6 and later and on iOS, this is the preferred URL syntax
        let url = "https://app.bankid.com/";
        let androidVersion = /Android (\d+)/i.exec(navigator.userAgent)?.[1];
        if (androidVersion && parseInt(androidVersion, 10) < 6) {
            // Only preferred on Android < 6
            url = "bankid:///";
        }
        // We need to add the "#" to prevent Safari from reloading
        let redirect = /ipad|iphone|ipod/i.test(navigator.userAgent) ? encodeURIComponent(window.location.href + "#") : null;
        window.location.href = `${url}?autostarttoken=${autoStartToken}&redirect=${redirect}`;
      }
      saveCancelRef(initialUserAuthResponse.data);

      let userAuthResponse = { status: "pending" };
      while (userAuthResponse.status === "pending") {
        await new Promise((r) => setTimeout(r, 2000));
        const res = await Axios.put(
          `${settings.apiBase}/api/authentication/user/token/${orderRef}`,
          { scope: "", source: "Onboarding" }
        );
        userAuthResponse = res.data;
        qrCode(userAuthResponse);
      }
      if (userAuthResponse.status === "failed") {
        setIsAuthenticating(false);
        if (userAuthResponse.hint === "userCancel") {
          setErrorMessage(applicationTexts.bankIDLoginWasCancelled);
        } else if (userAuthResponse.hint === "expiredTransaction") {
          setErrorMessage(applicationTexts.bankIDSessionExpired);
        } else {
          setErrorMessage(applicationTexts.bankIDGeneralErrorMessage);
        }
        return;
      }
      setAuthenticationInformation(userAuthResponse);
    } catch (error) {
      setIsAuthenticating(false);
      setErrorMessage(error.errorMessage);
    }
  };

  return (
    <div className="view authentication-view"> 
    {
      isAuthenticating ? (
        <div className="container waiting-container">
          <h2 className="header view-header h2">{applicationTexts.identifyYourselfInBankIdAppHeaderText}</h2>
          <div className="message info-message">
            { loginOnSameDevice ? (
            applicationTexts.openBankIDInfoMessageText
            ):(
            applicationTexts.openBankIDQRInfoMessageText
            )}</div>
            <ContainerWithSpinner isLoading={!animatedQrCode} >
              <img alt="BankID QR-code" className="qr-code-img" src={animatedQrCode} />
            </ContainerWithSpinner> 
            <p>
              <button 
                className="link" 
                onClick={() => { 
                  cancelLogin(cancelRef);
                  setAnimatedQrCode("");
                }}>{applicationTexts.bankIDLoginCancel}</button>  
            </p>
          <div className="bank-id-stand-alone-logo"></div>
        </div>
      )
        :
      (
        <div>
      <h2 className="header view-header h2">{applicationTexts.authenticationHeader}</h2>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="input-container sign-in-input-container">

          {(errorMessage && !cancelStatusOk) && (
            <div className="message error-message">
              {errorMessage}
            </div>
          )}
          {(errorMessage && cancelStatusOk) && (
            <div className="message error-message">
              {applicationTexts.bankIDLoginWasCancelled}
            </div>
          )}
          { showPersonalNumberInput && (
          <>
            <label className="label personal-number-input-label">{applicationTexts.personalNumberInputPlaceholderText}</label>
            {errors && errors.personalNumber && (
              <span className="message validation-message error-message">
                {errors.personalNumber.message}
              </span>
            )}
            <input 
              className="input personal-number-input bank-id-input"
              disabled={isAuthenticating}
              name="personalNumber"
              type="text"
              placeholder={applicationTexts.personalNumberInputPlaceholderText}
              ref={register({})}
            />
          </>
          )
          } 
          
          <button className="button sign-in-button bank-id-button" type="submit" value="Submit" disabled={isAuthenticating}>
            {applicationTexts.bankIDLoginButtonText}
              <div className="button-icon"></div>
          </button>
          

          {
          loginOnSameDevice ? (
            <button onClick={() => { 
              setLoginOnSameDevice(false);
              setAnimatedQrCode("");
            }} 
            className="link bank-id-device-change" type="submit" value="Submit" disabled={isAuthenticating}>
            {applicationTexts.openBankIDOnOtherDeviceButtonText}
            <div className="button-icon"></div>
          </button>
                      
          ) : (
            <button 
              onClick={() => {
              setLoginOnSameDevice(true);
              setAnimatedQrCode("");
             }} 
             className="link bank-id-device-change" type="submit" value="Submit" disabled={isAuthenticating}>
              {applicationTexts.openBankIDOnSameDeviceButtonText}
              <div className="button-icon"></div>
            </button>
          )
        }



        </div>
      </form>
      <p className="paragraph">
        {applicationTexts.authenticationBodyText}
      </p>
    </div>
      )
            }
    <Footer/>
  </div>

)};
