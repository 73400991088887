import React, { useState } from "react";
import applicationTexts from "../applicationTexts.json";
export default function FileUpload({ onChange, accept }) {
  const [labelText, setLabelText] = useState(
    applicationTexts.uploadFilePlaceHolder
  );
  const _onChange = (e) => {
    if (!e.target.files.length) {
      return;
    }
    setLabelText(e.target.files[0].name);
    onChange(e.target.files[0]);
  };





  
  return (
    <div className="container upload-component">
      <input
        id="fileupload"
        className="input file-input"
        type="file"
        accept={accept}
        onChange={_onChange}
      />
      <label className="label upload-label" htmlFor="fileupload">
        <div className="upload-icon"></div>
        <span>{labelText}</span>
      </label>
    </div>
  );
}
