import React from "react";
import Spinner from "./Spinner";

export default function ContainerWithSpinner({ children, isLoading }) {
  return (
    <div className="container spinner-container">
      <div className="spinner-vertical-align">
        {isLoading && <Spinner />}
        {!isLoading && <>{children}</>}
      </div>
    </div>
  );
}
