import React, { useState, useEffect } from "react";
import Select from "react-select";

export default function DropdownComponent({
  options,
  selectedItem,
  handleSelect,
  noOptionsMessage,
  dropdownPlaceholder,
}) {
  const [_selectedItem, setSelectedItem] = useState(selectedItem);
  useEffect(() => {
    if (selectedItem !== _selectedItem) {
      setSelectedItem(selectedItem);
    }
  }, [selectedItem,_selectedItem]);
  const onChange = (value) => {
    setSelectedItem(value);
    handleSelect(value);
  };
  const customStyles = {
    menu: () => ({}),
    menuList: () => ({}),
    container: () => ({}),
    option: () => ({}),
    control: () => ({}),
    singleValue: () => ({}),
    group: () => ({}),
    valueContainer: () => ({}),
    placeholder: () => ({}),
    clearIndicator: () => ({}),
    dropdownIndicator: () => ({}),
    groupHeading: () => ({}),
    indicatorsContainer: () => ({}),
    indicatorSeparator: () => ({}),
    input: () => ({}),
    loadingIndicator: () => ({}),
    loadingMessage: () => ({}),
    menuPortal: () => ({}),
    multiValue: () => ({}),
    multiValueLabel: () => ({}),
    multiValueRemove: () => ({}),
    noOptionsMessage: () => ({})
  };
  return (
      <Select
        className="select-box"
        classNamePrefix="select-box"
        styles={customStyles}
        value={_selectedItem}
        placeholder={dropdownPlaceholder}
        options={options}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        isSearchable={false}
        /* menuIsOpen={true} */ 
      >
        </Select>
  );
}

