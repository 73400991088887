import React, { useState } from "react";
import applicationTexts from "../applicationTexts.json";
import { useForm } from "react-hook-form";
import Footer from "../components/Footer";
import settings from "../settings/index";
import { useHistory, useLocation, useParams } from "react-router";
import Axios from "axios";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import { Link } from "react-router-dom";

export default function InviteView({ customerInformation, creditor, isLoggedIn, logout }) {
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const { id } = useParams();
  const location = useLocation();
  const { company } = location.state;
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (formData) => {
    try {
      setIsLoading(true);
      const res = await Axios.post(
        `${settings.apiBase}/api/aspnetusers/invitation/${company.id}`,
        {
          email: formData.email,
          role: "accountant",
          inviteeTask: "connectEconomicSystem",
        },
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      history.replace(`/${id}/success`, {
        company,
        invitation: res.data,
      });
      return;
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <ContainerWithSpinner isLoading={isLoading}>
      <div className="view invite-view">
      <h2 className="header view-header h2">{applicationTexts.inviteViewHeaderText}</h2>
      <form className="form" onSubmit={handleSubmit(submit)}>
        <div className="container invite-container">
          <label className="label invite-input-label">{applicationTexts.financeManagerEmailLabel}</label>
          <input
            className="input email-input invite-input"
            type="email"
            placeholder={applicationTexts.financeManagerEmailLabel}
            ref={register({
              required: applicationTexts.mandatoryField,
            })}
            name="email"
          />
          <p className="paragraph">{applicationTexts.inviteViewDescriptionText}</p>
          <button className="button invite-submit-button" type="submit">
            {applicationTexts.inviteButtonText}
            <div className="button-icon"></div>
          </button>
        </div>
      </form>
        <div className="view-links">
        <Link 
          className="link to-consent-view-link"
          to={`/${id}/consent`} replace>
          {applicationTexts.toConsentViewLinkText}
        </Link>
        </div>
        <Footer 
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />
      </div>
    </ContainerWithSpinner>
  );
}
