import React, { useEffect, useState } from "react";
import { creditorAgreementText } from "../components/sharedComponents";
import { useHistory, useParams, useLocation } from "react-router";
import { GetObjectPartOfPayload } from "../utils";
import Axios from "axios";
import settings from "../settings/index";
import Footer from "../components/Footer";
import OrganizationPickerComponent from "../components/OrganizationPickerComponent";
import { Link } from "react-router-dom";
import { Checkbox } from "../components/formComponents";
import { useForm, Controller } from "react-hook-form";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";

export default function ConnectCompanyView({ customerInformation, creditor, isLoggedIn, logout  }) {

  const [eligibleEngagements, setEligibleEngagements] = useState([]);
  const [nonEligibleEngagements, setNonEligibleEngagements] = useState([]);
  const [engagementsPayload, setEngagementsPayload] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const hasCompanies = location.state && location.state.hasCompanies;
  const [errorMessage, setErrorMessage] = useState("");
  const [userToken, setUserToken] = useState("");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hasEngagements, setHasEngagements] = useState(true);
  const { handleSubmit, errors, control, watch } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    setUserToken(customerInformation.accessToken);
      fetchOrganizations(); // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customerInformation]);



  const addCompany = async (formData) => {
    setErrorMessage("");
    const payload = {
      onboardingCreditor: creditor.clientIdAuth0,
      companyName: formData.organization.companyName,
      organizationNumber: formData.organization.companyId,
      payload: engagementsPayload,
      type: 0,
      creationSource: "OnboardingIFrame",
    };
    try {
      setIsLoading(true);
      const res = await Axios.post(
        `${settings.apiBase}/api/companies`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const { creditorGuid: id } = creditor;
      history.replace(`/${id}/connect-erp`, {
        company: res.data,
        hasAcceptedCreditor: true,
      });
      return;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          setErrorMessage(
            applicationTexts.organizationLookupCompanyAlreadyExists
          );
          setIsLoading(false);
          return;
        }
      }
      setIsLoading(false);
      setErrorMessage(applicationTexts.unknownError);
    }
  };

  const setEngagementsFromPayload = (_payload) => {
    const { engagements: _engagements } = JSON.parse(
      GetObjectPartOfPayload(_payload)
    );
    if (_engagements && _engagements.length) {
      setEligibleEngagements(_engagements.filter((x) => x.eligibleRole));
      setNonEligibleEngagements(_engagements.filter((x) => !x.eligibleRole));  
    } else {
      setHasEngagements(false);
    }
  };

  const fetchOrganizations = async () => {
    try {
      setIsLoading(true);
      const res = await Axios.get(
        `${settings.apiBase}/api/companies/engagements`,
        { headers: { Authorization: `Bearer ${customerInformation.accessToken}` } }
      );

      if (res.data) {
        setEngagementsPayload(res.data);
        setEngagementsFromPayload(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };



  return (
    <ContainerWithSpinner isLoading={isLoading}>

      <div className={`view connect-company-view`}>

        <h2 className="">{applicationTexts.connectCompanyHeaderText} </h2>

      {errorMessage && <div className="message error-message">{errorMessage}</div>}
    
      {hasEngagements ? (
        <div className="container connect-company-container">
        <form className="form" onSubmit={handleSubmit(addCompany)}>
            {errors.organization && 
            <div className="message error-message">
              {errors.organization.message}
            </div>
            }
            {
              eligibleEngagements.length === 0 && (
                <div className="message warning-message">{ applicationTexts.noAdditionalEligableCompaniesFoundText }</div>
              )
            }
            {
              (eligibleEngagements.length > 0 || nonEligibleEngagements.length > 0) && (
              <Controller
                as={
                  <OrganizationPickerComponent
                    selectedValue={ watch("organization") }
                  />
                }
                eligibleEngagements={ eligibleEngagements }
                nonEligibleEngagements={ nonEligibleEngagements }
                setErrorMessage={ setErrorMessage }
                rules={{ required: applicationTexts.noCompanyChosen }}
                control={control}
                name="organization"
                onChange={(s) => {
                  return s[0];
                }}
              />
              )}
              {
                eligibleEngagements.length > 0 && (
                <>
                  <CheckboxComponent
                    checkboxLabel={ creditorAgreementText(creditor) }
                    requiredMessage={applicationTexts.creditorTermsNotAccepted.replace(
                      "{creditorName}",
                      creditor.creditorName
                    )}
                    control={control}
                    name="myNumbersTerms"
                    errors={errors}
                  />
                  <button className="button connect-company-button" type="submit">
                    {applicationTexts.createCompanyButtonText}
                    <div className="button-icon"></div>
                  </button>
                </>
              )
            }
        </form>
        </div>
      )
      :
      (
        (
          <div>
            <div className="message warning-message">
              {applicationTexts.noAdditionalCompanyAssociatedWithPersonalNumber}
            </div>
            <a href="https://mynumbers.com/site/skapa-konto/" target="_tab" className="link eligable-roles-info-link">{applicationTexts.eligableRolesInfoText}</a>
          </div>
        )
      )
      
      }
      {hasCompanies && (
        <div className="view-links">
        <Link 
        className="link to-consent-view-link"
        to={`/${id}/consent`} replace>
          {applicationTexts.toConsentViewLinkText}
          
        </Link>
        </div>
      )}
        <Footer 
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />
      </div>
    </ContainerWithSpinner>
  );
}

const CheckboxComponent = ({
  checkboxLabel,
  errors,
  control,
  style,
  requiredMessage,
  name,
}) => {
  const _checkboxOnChange = (value) => value[0];
  return (
    <>
      { errors[name] &&
        <div className="message error-message checkbox-error-message">
          {errors[name].message}
        </div>
      }
      <Controller
        as={<Checkbox />}
        label={checkboxLabel}
        style={style}
        type="checkbox"
        name={name}
        valueName="checked"
        control={control}
        rules={{ required: requiredMessage }}
        onChange={_checkboxOnChange}
      />
    </>
  );
};
