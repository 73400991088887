import React from "react";
import Footer from "../components/Footer";
import { useLocation } from "react-router";
import applicationTexts from "../applicationTexts.json";

export default function ErrorView() {
  const location = useLocation();
  const { errorMessage } = location.state || { errorMessage: "" };
  return (
    <div className="view error-view">
      {applicationTexts.errorViewErrorDescription} {errorMessage}
      <Footer />
    </div>
  );
}
