import React, { useState } from "react";
import DropdownComponent from "../components/DropdownComponent";
import applicationTexts from "../applicationTexts.json";

export default function OrganizationPickerComponent({
  payload,
  onChange,
  setErrorMessage,
  eligibleEngagements,
  nonEligibleEngagements,
}) {

  const [nonEligibleListIsOpen, setNonEligibleListIsOpen] = useState(false);
  const selectBoxOptions = eligibleEngagements.map(engagement => ({value: engagement.companyId, label: engagement.companyName + "  (" + engagement.companyId.slice(0, 6) + "-" + engagement.companyId.slice(6) + ")"}));

  const onSelectCompany = (company) => {
    const selectedCompanyId = company.value;
    for (let i = 0; i < eligibleEngagements.length; i++) {
      if (eligibleEngagements[i].companyId === selectedCompanyId) {
        handleOnChange(eligibleEngagements[i]);
      }
    }
    return;
  };

  const toggelNonEligableList = () => {
    setNonEligibleListIsOpen(!nonEligibleListIsOpen)
  }

  const handleOnChange = async (selectedCompany) => {
    if (!selectedCompany) {
      onChange(null);
      return;
    }
    if (!selectedCompany.eligibleRole) {
      setErrorMessage(
        applicationTexts.nonEligibleErrorMessage.replace(
          "{companyName}",
          selectedCompany.companyName
        )
      );
      onChange(null);
      return;
    }
    onChange({ ...selectedCompany, payload });
  };

  return (

      <div className="container onboard-company-container">
          <>
            {eligibleEngagements.length !== 0 && (
              <div>
                <label className="label organization-picker-label">{applicationTexts.organizationPickerLabelText}</label>  
                <DropdownComponent
                  options={selectBoxOptions}
                  handleSelect={onSelectCompany}
                  dropdownPlaceholder={applicationTexts.companyDropdownPlaceholder}
                  noOptionsMessage={() => applicationTexts.noEligableCompaniesFoundDropdownText}
                />
              </div>
            )
            }

            {nonEligibleEngagements.length > 0 && (
            nonEligibleEngagements.length === 1 ? (
              
                <div>
                  {eligibleEngagements.length === 0 ? 
                    <label className="label eligable-companies-label">
                      {applicationTexts.eligableCompaniesLabel}
                    </label>
                  :
                    <label className="label non-eligable-companies-label">
                      {applicationTexts.nonEligableCompaniesLabel}
                    </label>
                  }
                  <div className="non-eligable-company">
                    <h3 className="header company-name-haeder h3">{nonEligibleEngagements[0].companyName}</h3>
                    <div className="message warning-message icon">{applicationTexts.notEligableErrorMessage}</div>
                  </div>
                </div>              
            )
            : 
            (
              <div className="container companies-container">
                <div className="message non-eligable-companies-found-message">{applicationTexts.noEligableCompaniesFoundInfoText.replace('{numberOfNonEligableEngagements}', nonEligibleEngagements.length
            )} <div className="link show-eligable-roles-list-link" onClick={() => toggelNonEligableList()}>{nonEligibleListIsOpen ? applicationTexts.closeListLinkText : applicationTexts.showListLinkText}</div></div>
                <div className={`container fold-out-container is-${nonEligibleListIsOpen ? "open" : "closed"}`}>
                <label className="label non-eligable-companies-list-label">{applicationTexts.nonEligableCompaniesLabel}</label>
                {nonEligibleEngagements.map((engagement, index) => (
                  <div key={index} className="non-eligable-company-list-item"><h3 className="header company-name-haeder h3">{engagement.companyName}</h3></div> 
                ))}
                </div>
              </div>
              )
            )}
          </>
      </div>
  );
}

