import React, { useEffect, useState } from "react";
import {
  creditorAgreementText,
} from "../components/sharedComponents";
import { GetObjectPartOfPayload } from "../utils";
import settings from "../settings/index";
import Footer from "../components/Footer";
import { Checkbox } from "../components/formComponents";
import Axios from "axios";
import OrganizationPickerComponent from "../components/OrganizationPickerComponent";
import { Controller, useForm } from "react-hook-form";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";
import { Link, useParams } from "react-router-dom";

export default function UserRegisterView({
  authenticationInformation,
  creditor,
  setCustomerInformation,
}) {
  const { id } = useParams();
  const [eligibleEngagements, setEligibleEngagements] = useState([]);
  const [nonEligibleEngagements, setNonEligibleEngagements] = useState([]);
  const [name, setName] = useState("");
  const [eligibleList, setCustomerEligibleList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [userToken, setUserToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companyRequired, setCompanyRequired] = useState({required: applicationTexts.noCompanyChosen}); 

  const { register, handleSubmit, errors, control, watch } = useForm({
    mode: "onBlur",
  });
  
  useEffect(() => {
    const token = authenticationInformation.userRegistrationToken.user;
    setUserToken(token);
    const { name: _name } = JSON.parse(
      authenticationInformation.userRegistrationToken.user.split("|")[3]
    );
    setName(_name);
  }, [authenticationInformation.userRegistrationToken.user]);

  useEffect(() => {
    if(!authenticationInformation.userRegistrationToken || !authenticationInformation.userRegistrationToken.engagements) {
      return;
    }
    const engagementsBaseIncludingToken = authenticationInformation.userRegistrationToken.engagements;
    const { engagements: _engagements } = JSON.parse(
      GetObjectPartOfPayload(engagementsBaseIncludingToken)
    );
    if(!_engagements || _engagements.length === 0) {
      return;
    } 
    let _eligibleEngagements = _engagements.filter((x) => x.eligibleRole); 
    setEligibleEngagements(_eligibleEngagements);
    setNonEligibleEngagements(_engagements.filter((x) => !x.eligibleRole));  

    if (_eligibleEngagements.length === 0)  { 
      setCompanyRequired({required: false});
      eligableMembers(engagementsBaseIncludingToken);
    }

  }, [authenticationInformation]);


  const companyByOrganizationNumber = (organizationNumber) => {
    const company = nonEligibleEngagements.find( ({ companyId }) => companyId === organizationNumber );
    if (company != null) {
      return company.companyName;
    }
    return organizationNumber;
  }


  const eligableMembers = async (engagementsPayload) => {

    try {
      const res = await Axios.post(
        `${settings.apiBase}/api/engagements/eligiblemembers/multiple`,
        {engagementsPayload:engagementsPayload}
      );
      setCustomerEligibleList(res.data);

    } catch (error) {
      setErrorMessage(error.errorMessage);

    }

  }


  const onRegister = async (formData) => {

    let type = 4; // "missingEngagementsRegistration";

    let companyName = "";
    let companyId = "";
    let inviteeEmail = null;

    if (formData.organization && formData.organization.companyName && formData.organization.companyId) {
      companyName = formData.organization.companyName;
      companyId = formData.organization.companyId;
      type = 0;
    } 
    
    if (formData.inviteeEmail) {
      inviteeEmail = formData.inviteeEmail;
    }

    const payload = {
      userRegistrationToken: userToken,
      email: formData.email,
      inviteeEmail: inviteeEmail,
      engagementsToken: authenticationInformation.userRegistrationToken.engagements,
      companyPayload: {
        onboardingCreditor: creditor.clientIdAuth0,
        companyName: companyName,
        organizationNumber: companyId,
        payload: authenticationInformation.userRegistrationToken.engagements,
        type: type,
        creationSource: "OnboardingIFrame",
      },
    };
    try {
      setIsLoading(true);
      const res = await Axios.post(
        `${settings.apiBase}/api/authentication`,
        payload
      );
      setCustomerInformation(res.data);
    } catch (error) {
      setErrorMessage(error.errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <ContainerWithSpinner isLoading={isLoading}>
      <div className="view register-user-view">
        <h2 className="header view-header h2">{applicationTexts.userRegisterHeaderText}</h2>

        {errorMessage && <div className="message error-message">{errorMessage}</div>}
        
          <p className="paragraph">{applicationTexts.namelabel}:{'\u00A0'}<span>{name}</span></p>                  
          <div className="container user-register-container">
            <form className="form" onSubmit={handleSubmit(onRegister)}>
              <div className="container input-container user-register-input-container">
                              
                <h3 className="label register-user-label">{applicationTexts.emailLabel}</h3>
                {
                  errors.email &&
                    <div className="message error-message icon">
                      {errors.email.message}
                    </div>
                }
                <input 
                  name="email"
                  type="email"
                  className="input email-input"
                  ref={register({ required: applicationTexts.mandatoryField })}
                  placeholder={applicationTexts.emailLabel}
                />

                {
                  (eligibleEngagements.length > 0 || nonEligibleEngagements.length > 0) && (
                  <>
                    {
                      errors.organization &&
                        <div className="message error-message icon">
                          { errors.organization.message}
                        </div>
                    }

                    <Controller
                      as={ <OrganizationPickerComponent selectedValue={watch("organization")} /> }
                      name="organization"
                      eligibleEngagements={ eligibleEngagements }
                      nonEligibleEngagements={ nonEligibleEngagements }
                      setErrorMessage={ setErrorMessage }
                      rules={companyRequired}
                      control={ control }
                      onChange={(s) => { return s[0]; }}
                    />
                    {eligibleEngagements.length > 0 &&
                      <CheckboxComponent
                        name="creditorTerms"
                        checkboxLabel={creditorAgreementText(creditor)}
                        requiredMessage={applicationTexts.creditorTermsNotAccepted.replace(
                          "{creditorName}",
                          creditor.creditorName
                        )}
                        control={control}
                        errors={errors}
                      />
                    }
                  </>
                  )
                }

                {
                  eligibleEngagements.length === 0 && (
                  <>
                    { nonEligibleEngagements.length === 0 && (
                      <div className="message warning-message">
                        {applicationTexts.noEligableCompaniesFoundText}
                      </div>
                    )}                    
                    <input type="hidden" name="companyName" value="" />
                    <input type="hidden" name="companyId" value="" />                  
                    <div className="message warning-message"
                        dangerouslySetInnerHTML={{
                          __html: applicationTexts.noCompanyAssociatedWithPersonalNumber,
                        }}>
                    </div>
                    <p><a href="https://mynumbers.com/site/skapa-konto/" target="_tab" className="link eligable-roles-info-link">{applicationTexts.eligableRolesInfoText}</a></p>
                    <h3 className="label register-user-label">E-post till person att bjuda in</h3>
                    <input 
                      name="inviteeEmail"
                      type="email"
                      className="input email-input"
                      ref={register({ required: applicationTexts.mandatoryField })}
                      placeholder="E-post till person att bjuda in"
                    />
                    { eligibleList.length > 0 &&
                      <div className="message warning-message">
                        {applicationTexts.eligableRolesListText}
                        <ul>
                          {eligibleList.map((company, i) => ( 
                                <li key={"company" + i} >
                                    {companyByOrganizationNumber(company.companyId)}
                                    <ul>
                                      {company.boardMembers.map((member, j) => (
                                          <li key={`member${i}_${j}`}>
                                          { member.firstName } { member.surName }  <br />
                                          </li>
                                        )
                                      )
                                    }
                                  </ul>
                                </li>
                              )
                          )}
                        </ul>
                    </div>
                    }
                  </>
                  )
                }  

                <>
                  <CheckboxComponent
                    checkboxLabel={applicationTexts.myNumbersAgreementText}
                    requiredMessage={applicationTexts.myNumbersTermsNotAccepted}
                    control={control}
                    name="myNumbersTerms"
                    errors={errors}
                  />
                  <button className="button user-register-button" type="submit">
                    {applicationTexts.createAccountButtonText}
                    <div className="button-icon"></div>
                  </button>
                </>
        
              </div>
            </form>
          </div>
          
          <div className="view-links">
            <Link
              className="link to-start-view-link"
              to={`/${id}/`}
              replace
            >
              {applicationTexts.cancelButtonText}
            </Link>
          </div>
          <Footer/>
      </div>
    </ContainerWithSpinner>
  );
}

const CheckboxComponent = ({
  checkboxLabel,
  errors,
  control,
  style,
  requiredMessage,
  name,
}) => {
  const _checkboxOnChange = (value) => value[0];
  return (
    <>
      {errors[name] && 
      <div className="message error-message checkbox-error-message icon">
       {errors[name].message}
      </div>
      }
      <Controller
        as={<Checkbox />}
        label={checkboxLabel}
        style={style}
        type="checkbox"
        name={name}
        valueName="checked"
        control={control}
        rules={{ required: requiredMessage }}
        onChange={_checkboxOnChange}
      />
    </>
  );
};


