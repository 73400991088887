import React from "react";

export default function ToggleSwitch({ onClick, checked, style }) {
  return (
    <label
      className="toggle-switch"
      style={style}
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
    >
      <input 
      className="input"
      checked={checked} 
      type="checkbox"
      readOnly
      />
      <span
        role="switch"
        aria-checked={checked}
        className="toggle-slider "
      />
    </label>
  );
}

