import React from "react";
import { Link } from "react-router-dom";
import applicationTexts from "../applicationTexts.json";
import mynumbersLogo from "./../images/mynumberslogo.svg";


export default function Footer({creditor, isLoggedIn, logout}) {

  return (
    <div className="mynumbers-logo-container">
    {creditor && isLoggedIn() && (
      <Link
        className="link logout-link"
        to={`/${creditor.creditorGuid}`}
        onClick={logout}
        replace
      >
        {applicationTexts.logoutButtonText}
      </Link>
    )}
    <img src={mynumbersLogo} alt="logo" className="mynumbers-logo" />
  </div>
  )
}

