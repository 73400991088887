import React from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import applicationTexts from "../applicationTexts.json";

export default function SuccessfullyConnectedView({creditor, isLoggedIn, logout}) {
  const location = useLocation();
  const { company, erpConnection, invitation } = location.state;
  const { id } = useParams();

  const FileUploadLink = () => {
      return( 
              <Link
                className="link to-file-upload-view-link"
                to={{
                pathname: `/${id}/upload-file`,
                  state: {
                    company,
                    hasAcceptedCreditor: company.hasAcceptedCreditor,
                  },
                }}
                replace
                >
                Ladda upp ytterligare en fil
              </Link>
            );
  }

  return (
    <div className="view successfully-conected-view">
      <div>
        <h2 className="header view-header h2">{applicationTexts.successFullyConnectedViewHeader}</h2>
      </div>
      {
        (erpConnection && company.extractorType) && (
          <div>
          
          <p className="paragraph">
            {applicationTexts.additionalFileSuccessfullyUploaded}
          </p>
            <FileUploadLink />
          </div>
        )
      }
      {
        (erpConnection && !company.extractorType && erpConnection === "SIE4") && (
            <div><p
            className="paragraph"
            data-testid="erp-connection-element"
            dangerouslySetInnerHTML={{
              __html: applicationTexts.successFullyConnectedSIE4ViewBody
                .replace("{companyName}", company.name),
            }}
          />
            <FileUploadLink />
          </div>
        )
        }
        {   
        (erpConnection && !company.extractorType && erpConnection !== "SIE4") && (
            <p
            className="paragraph"
            data-testid="erp-connection-element"
            dangerouslySetInnerHTML={{
              __html: applicationTexts.successFullyConnectedERPViewBody
                .replace("{companyName}", company.name)
                .replace("{erpConnection}", erpConnection),
            }}
          />
        )
      }

      {!erpConnection && invitation && (
        <>
          <p
           className="paragraph"
            data-testid="invitation-element"
            dangerouslySetInnerHTML={{
              __html: applicationTexts.successFullyCreatedInvitation.replace(
                "{email}",
                invitation.email
              ),
            }}
          />
          <p className="paragraph">
            {applicationTexts.incorrectEmailText}{" "}
            <Link
              className="link to-invite-view-link"
              to={{
                pathname: `/${id}/invite`,
                state: {
                  company,
                },
              }}
            >
              {applicationTexts.incorrectEmailLinkText}
            </Link>
          </p>
        </>
      )}

      <div className="view-links">
      <Link 
        className="link to-consent-view-link"
        to={`/${id}/consent`} replace>
        {applicationTexts.toConsentViewLinkText}
      </Link>
      </div>
      <Footer 
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />
    </div>
  );
}
