import React from "react";
import logo from "../../images/peaccounting.png";
import ModalInfoButton from "../ModalInfoButton";
import applicationTexts from "../../applicationTexts.json";

export default function PEAccountingComponent({
  children,
  register,
  errors,
  submit,
  touched,
}) {
  const handleClick = async (e) => {
    e.preventDefault();
    submit();
  };
  return (
    <div className="container erp-container">
      <div className="erp-header">
      <img className="erp-logo" src={logo} alt="logo" />
        <ModalInfoButton
          modalBody={
            <span
              dangerouslySetInnerHTML={{
                __html: applicationTexts.peAccountingInformationModalText,
              }}
            />
          }
        >
          i
        </ModalInfoButton>
      </div>
      <div className="input-container erp-input-container">
      { errors.username &&
        <div className="message error-message">
          {errors.username.message}
        </div>
        }
        <label className="label erp-input-label">{applicationTexts.peAccountingEmailInputPlaceHolder}</label>
        <input
          className="input email-input erp-input"
          name="username"
          type="email"
          placeholder={applicationTexts.peAccountingEmailInputPlaceHolder}
          isInvalid={errors.username}
          isValid={touched.username && !errors.username}
          ref={register({
            required: applicationTexts.mandatoryField,
          })}
        />
        {errors.password &&
        <div className="message error-message">
          {errors.password.message}
        </div>
        }
        <label className="label erp-input-label">{applicationTexts.peAccountingPasswordInputPlaceHolder}</label>
        <input
          className="input erp-input"
          name="password"
          type="password"
          placeholder={applicationTexts.peAccountingPasswordInputPlaceHolder}
          isInvalid={errors.password}
          isValid={touched.password && !errors.password}
          ref={register({
            required: applicationTexts.mandatoryField,
          })}
        />
        {children}
        <button className="button erp-submit-button" type="submit" onClick={handleClick}>
          {applicationTexts.connectCompanyToErpButtonText}
          <div className="button-icon"></div>
        </button>
      </div>
    </div>
  );
}
