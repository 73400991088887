import React from "react";
import logo from "../../images/bjornlunden.png";
import ModalInfoButton from "../ModalInfoButton";
import applicationTexts from "../../applicationTexts.json";

export default function BjornLundenComponent({
  children,
  register,
  errors,
  submit,
  touched,
}) {
  const handleClick = async (e) => {
    e.preventDefault();
    submit();
  };
  return (
    <div className="container erp-container">
      <div className="erp-header">
        <img className="erp-logo" src={logo} alt="logo" />
        <ModalInfoButton
          modalBody={
            <span
              dangerouslySetInnerHTML={{
                __html: applicationTexts.bjornLundenInformationModalText,
              }}
            />
          }
        >
          i
        </ModalInfoButton>
      </div>
      <div className="input-container erp-input-container">
      {errors.key &&
        <div className="message error-message">
          {errors.key.message}
        </div>
        }
        <label className="label erp-input-label">{applicationTexts.bjornLundenIdCodeInputPlaceholder}</label>
        <input 
          className="input erp-input"
          type="text"
          name="key"
          placeholder={applicationTexts.bjornLundenIdCodeInputPlaceholder}
          isInvalid={errors.key}
          isValid={touched.key && !errors.key}
          ref={register({
            required: applicationTexts.mandatoryField,
          })}
        />
        {children}
        <button className="button erp-submit-button" type="submit" onClick={handleClick}>
          {applicationTexts.connectCompanyToErpButtonText}
          <div className="button-icon"></div>
        </button>
      </div>
    </div>
  );
}
