import React, { useState, useRef } from "react";
import settings from "../../settings/index";

import logo from "../../images/fortnox_logo.png";
import { getQueryObject } from "../../helper";
import ModalInfoButton from "../ModalInfoButton";
import applicationTexts from "../../applicationTexts.json";

export default function FortnoxComponent({
  children,
  submit,
  register,
  errors,
  hasCheckedAccept,
  hasAcceptedCreditor,
}) {
  let loginWindow;
  let timer;
  let uid;
  const [_code, setCode] = useState("");
  const [connectionCanceled, setConnectionCanceled] = useState(false);
  const submitButtonRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    submit();
    if (!_code && (hasCheckedAccept || hasAcceptedCreditor)) {
      initiateFortnoxLogin();
    }
  };

  const initiateFortnoxLogin = () => {
    if (!window || !window.open || typeof setInterval !== "function") {
      return;
    }
    uid = `s_${new Date().valueOf().toString()}`;
    let loginWindowWidth = window.screen.width;
    let loginWindowHeight = window.screen.height;
    if (loginWindowWidth > 1024) {
      loginWindowWidth = 1024;
    }
    if (loginWindowHeight > 768) {
      loginWindowHeight = 768;
    }
    const authUrl = createAuthUrl(uid);
    loginWindow = window.open(
      authUrl,
      "myWindow",
      "width=" + loginWindowWidth + ",height=" + loginWindowHeight
    );
    timer = setInterval(checkIfComplete, 1000);
  };

  const checkIfComplete = () => {
    let href;
    try {
      href = loginWindow.location.href; // eslint-disable-line prefer-destructuring
    } catch (e) {
      return;
    }

    if (href) {
      const query = loginWindow.location.search;
      const { code, state, error } = getQueryObject(query);
      if (href.indexOf("error=access_denied") !== -1) {
        setConnectionCanceled(true);
      }
      setCode(code);
      if (error) {
        return;
      }
      if (uid === state) {
        submit();
      }

      clearInterval(timer);
      loginWindow.close();
      loginWindow = null;
    } else if (loginWindow.closed) {
      clearInterval(timer);
    }
  };

  return (
    <div className="container erp-container">
      <div className="erp-header">
        <img className="erp-logo" src={logo} alt="logo" />
        <ModalInfoButton
          modalBody={
            <span
              dangerouslySetInnerHTML={{
                __html: applicationTexts.fortnoxInformationModalText,
              }}
            />
          }
        >
          i
        </ModalInfoButton>
      </div>
      <div className="input-container erp-input-container">
        <input
          className="input erp-input"
          type="hidden"
          name="code"
          ref={register({ required: true })}
          value={_code}
        />

        {children}
        {connectionCanceled && (
          <div className="message error-message">
            {applicationTexts.fortnoxConnectionCanceled}
          </div>
        )}
        <button
          className="button erp-submit-button"
          ref={submitButtonRef}
          onClick={handleClick}
          disabled={!hasCheckedAccept && !hasAcceptedCreditor}
        >
          {applicationTexts.connectCompanyToErpButtonText}
          <div className="button-icon"></div>
        </button>
      </div>
      <div className="input-container erp-input-container">
        {errors.apiKey && (
          <div className="message error-message">{errors.apiKey.message}</div>
        )}
      </div>
    </div>
  );
}

const createAuthUrl = (uid) => {
  let scope = encodeURIComponent(
    "bookkeeping companyinformation invoice costcenter customer payment project supplierinvoice settings supplier currency price order offer connectfile inbox archive"
  );
  let destinationUri = settings.fortnox.destinationUri;
  if (!destinationUri.includes("://")) {
    destinationUri = `${window.location.origin}${destinationUri}`;
  }
  let state = encodeURIComponent(`${uid},${destinationUri}`);
  const url = `${settings.fortnox.authHost}?client_id=${settings.fortnox.clientId}&response_type=code&access_type=offline&state=${state}&scope=${scope}`;
  return url;
};
