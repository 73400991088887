import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Axios from "axios";
import settings from "../settings/index";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import Footer from "../components/Footer";
import ModalInfoButton from "../components/ModalInfoButton";
import ToggleSwitch from "../components/ToggleSwitch";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";

export default function ConsentAdminView({ creditor, customerInformation, isLoggedIn, logout }) {
  const [creditors, setCreditors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionIsLoading, setActionIsLoading] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [actionButtonsVisible, setActionButtonsVisible] = useState(true);
  const [selectedCreditor, setSelectedCreditor] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { id } = useParams();
  const { selectedCompanyId } = useParams();
  const [selectedCompany, setSelectedCompany] = useState({});


  const toggle = () => {
    setIsShowing(!isShowing);
  }

  const toggleModal = (creditor) => {
    toggle();
    setSelectedCreditor(creditor);
  }

  const updateCreditor = async (creditor) => {

    setActionIsLoading(true);
    creditor.value = !creditor.value;
    const isConnected = creditor.value;

    const data = [creditor];
    try {
      await Axios.put(
        `${settings.apiBase}/api/onboarding/${selectedCompany.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );

      let message = isConnected ?
        applicationTexts.addedCreditorConfirmationText.replace("{creditorName}", creditor.name)
        :
        applicationTexts.removedCreditorConfirmationText.replace("{creditorName}", creditor.name)
      setActionIsLoading(false);
      setSuccessMessage(message);
      setActionButtonsVisible(false);

    } catch (error) {
      setIsLoading(false);
      setErrorMessage(applicationTexts.unknownError);
    }
  }


  const getStatus = async () => {
    setIsLoading(true);
    let _company = {};
    try {
      const res = await Axios.get(
        `${settings.apiBase}/api/companies/`,
        { headers: { Authorization: `Bearer ${customerInformation.accessToken}` } }
      );

      if (res.data) {
        setIsLoading(false);
        _company = res.data.find(c => c.id === selectedCompanyId);
      }

    } catch (error) {
      setIsLoading(false);
    }
    let _creditors = [];
    try {
      const res = await Axios.get(
        `${settings.apiBase}/api/onboarding/${selectedCompanyId}`,
        { headers: { Authorization: `Bearer ${customerInformation.accessToken}` } }
      );
      _creditors = res?.data
    } catch (error) {
      setIsLoading(false);
    }
    setCreditors(_creditors);
    setSelectedCompany(_company);
  };



  useEffect(() => {
    getStatus();
  }, []);


  const InformationModalBody = () => {
    return (
      <div className="modal-body">
        <p
          className="paragraph"
          dangerouslySetInnerHTML={{
            __html: applicationTexts.consentAdminViewInformationModalText.replace(
              /\{companyName}/g,
              selectedCompany.name
            ),
          }}
        ></p></div>
    );
  };

  return (
    selectedCompany &&
    <ContainerWithSpinner isLoading={isLoading}>
      <div className="view consent-view">
        <h2 className="header view-header h2">{selectedCompany.name}</h2>
        <div className="company-container">
          <div className="header-wrapper">
            <h3 className="header h3">
              {applicationTexts.consentAdminViewHeaderText}
              <ModalInfoButton modalHeader={applicationTexts.consentSettingsModalTitle} modalBody={<InformationModalBody />}>i</ModalInfoButton>
            </h3>
          </div>
        </div>
        {
          creditors.map((creditor) => (
            <div class="creditor-container">
              <h4>
                <ToggleSwitch
                  onClick={() => {
                    setSuccessMessage("");
                    setActionButtonsVisible(true);
                    toggleModal(creditor);
                  }}
                  checked={creditor.value}
                />
                {creditor.name}</h4>
              <div class="creditor-permissions">
                {applicationTexts.consentScope.replace("{creditorName}", creditor.name)}

                <ul>
                  {
                    creditor.permissions.map((p) => (
                      <li class="creditor-share-scope">
                        {applicationTexts["creditorPermissions." + p]}
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          ))
        }
      </div>

      <div className="view-links">
        <Link
          className="link to-consent-view-link"
          to={`/${id}/consent`} replace>
          {applicationTexts.toConsentViewLinkText}
        </Link>
      </div>
      <Footer
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />

      <Modal isShowing={isShowing} hide={toggle} headerText={
        !successMessage ? (
          selectedCreditor.value
            ?
            (applicationTexts.removeCreditorConnectionModalHeader.replace("{creditorName}", selectedCreditor.name))
            :
            (applicationTexts.confirmCreditorConnectionModalHeader.replace("{creditorName}", selectedCreditor.name))
        )
          :
          (
            applicationTexts.consentSettingsUpdated
          )
      }>

        <div className="modal-body consent-admin-overlay">
          <ContainerWithSpinner isLoading={actionIsLoading}>
            {errorMessage && <div className="message error-message icon">{errorMessage}</div>}
            {successMessage && <div className="message success-message icon">{successMessage}</div>}
            {
              !successMessage && (
                selectedCreditor.value ?
                  (applicationTexts.removeCreditorDefaultModalBodyText.replace("{creditorName}", selectedCreditor.name))
                  :
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: applicationTexts.confirmCreditorConnectionModalBodyText
                          .replace("{creditorName}", selectedCreditor.name)
                          .replace("{companyName}", selectedCompany.name)
                          .replace("{br}", "</br></br>"),
                      }}
                    />
                    <ul class="creditor-share-scope">
                      {
                        creditor.permissions.map((p, i) => (
                          <li>
                            {applicationTexts["creditorPermissions." + p]}
                          </li>
                        ))
                      }
                    </ul>
                  </>
              )
            }
            <div className="modal-footer">
              {actionButtonsVisible ?
                <>
                  <button onClick={() => toggle()} className="button modal-confirm-remove-button">
                    {applicationTexts.cancelButtonText}
                    <div className="button-icon"></div>
                  </button>
                  <button onClick={() => updateCreditor(selectedCreditor)} className="button modal-confirm-remove-button">
                    {selectedCreditor.value ?
                      applicationTexts.removeCreditorConnectionButtonText
                      :
                      applicationTexts.confirmCreditorConnectionButtonText
                    }
                    <div className="button-icon"></div>
                  </button>
                </>
                :
                <button onClick={() => toggle()} className="button modal-confirm-remove-button">{applicationTexts.closeModal}</button>
              }
            </div>
          </ContainerWithSpinner>
        </div>
      </Modal>
    </ContainerWithSpinner>
  );

}


