export const getQueryObject = (queryString) => {
  return queryString
    .substring(1)
    .split("&")
    .map((x) => {
      return { key: x.split("=")[0].toLowerCase(), value: x.split("=")[1] };
    })
    .reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
};

