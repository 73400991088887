import local from "./local.config";
import development from "./development.config";
import production from "./production.config";
import stage from "./stage.config";
const configuration = process.env.REACT_APP_CONFIGURATION;
let config;

if (!configuration || configuration === "local") {
  config = local;
}

if (configuration === "development") {
  config = development;
}

if (configuration === "stage") {
  config = stage;
}

if (configuration === "production") {
  config = production;
}
export default config;
