import applicationTexts from "../applicationTexts.json";


const creditorAgreementText = (creditor) => {
  return `${applicationTexts.creditorAgreementDefaultText.replace(
    "{creditorName}",
    creditor.creditorName
  )}
  <ul>
    ${creditor.permissions?.map(p => "<li>"+(applicationTexts["creditorPermissions."+p] || p)+"</li>").join("")}
  </ul>`;
};

export {
  creditorAgreementText
};
