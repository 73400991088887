import VismaComponent from "./VismaComponent";
import FortnoxComponent from "./FortnoxComponent";
import PEAccountingComponent from "./PEAccountingComponent";
import BjornLundenComponent from "./BjornLundenComponent";
import SIE4Component from "./SIE4Component";
import WintComponent from "./WintComponent";
import settings from "../../settings/index";

export const erpConfiguration = [

  {
    value: "visma",
    label: "Visma",
    component: VismaComponent,
    getConfiguration: ({ code }) => ({
      configurationSetting: [
        {
          key: "RedirectUri",
          value: settings.visma.redirectUri,
        },
        {
          key: "GrantToken",
          value: code,
        },
        {
          key: "ClientId",
          value: settings.visma.clientId,
        },
      ],
      extractorType: "VismaEekonomi",
    }),
  },  
  {
    value: "fortnox",
    label: "Fortnox",
    component: FortnoxComponent,
    getConfiguration: ({ code }) => ({
      configurationSetting: [
        {
          key: "RedirectUri",
          value: settings.fortnox.redirectUri,
        },
        {
          key: "AuthorizationCode",
          value: code,
        },
        {
          key: "ClientId",
          value: settings.fortnox.clientId,
        },
      ],
      extractorType: "Fortnox",
    }),
  },
  {
    value: "peaccounting",
    label: "PE Accounting",
    component: PEAccountingComponent,
    getConfiguration: ({ username, password }) => ({
      configurationSetting: [
        {
          key: "Username",
          value: username,
        },
        {
          key: "Password",
          value: password,
        },
      ],
      extractorType: "PEAccounting",
    }),
  },
  {
    value: "bjornlunden",
    label: "Björn Lundén",
    component: BjornLundenComponent,
    getConfiguration: ({ key }) => ({
      configurationSetting: [
        {
          key: "PublicKey",
          value: key,
        },
      ],
      extractorType: "BjornLunden",
    }),
  },
  {
    value: "wint",
    label: "Wint",
    component: WintComponent,
    getConfiguration: ({ companyId, apiKey }) => ({
      configurationSetting: [
        {
          key: "CompanyID",
          value: companyId,
        },
        {
          key: "APIKey",
          value: apiKey,
        },
      ],
      extractorType: "Wint",
    }),
  },
  {
    value: "sie4",
    label: "SIE4",
    component: SIE4Component,
  },
];
