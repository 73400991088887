import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { useParams } from "react-router";
import settings from "../settings/index";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";
import Footer from "../components/Footer";
export default function HomeView({ setCreditor }) {
  const { id } = useParams();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (!id.match(regex)) {
        history.replace("error", {
          errorMessage: applicationTexts.incorrectCreditorGUID,
        });
        return;
      }
      try {
        const { data: creditor } = await Axios.get(
          `${settings.apiBase}/api/onboarding/creditor/${id}`
        );
        setIsLoading(false);
        setCreditor(creditor, id);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          history.replace("/error", {
            errorMessage: applicationTexts.incorrectCreditorGUID,
          });
          return;
        }
        history.replace("/error", {
          errorMessage: applicationTexts.unknownError,
        });
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, history]);

  return <ContainerWithSpinner isLoading={isLoading}>
    <Footer/>
    </ContainerWithSpinner>;
}
