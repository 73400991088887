import React, { useState, useEffect } from "react";
import { creditorAgreementText } from "../components/sharedComponents";
import settings from "../settings/index";
import Footer from "../components/Footer";
import Axios from "axios";
import DropdownComponent from "../components/DropdownComponent";
import { useHistory, useParams, useLocation } from "react-router";
import { Checkbox } from "../components/formComponents";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";
import ModalInfoButton from "../components/ModalInfoButton";
import { erpConfiguration } from "../components/connectErp/erpConfiguration";

export default function ConnectErpView({
  customerInformation,
  creditor,
  isLoggedIn, 
  logout,
  erps = erpConfiguration,
}) {
  const [selectedErp, setSelectedErp] = useState(null);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasAcceptedCreditor, setHasAcceptedCreditor] = useState(false);
  const [hasCheckedAccept, setHasCheckedAccept] = useState(false);
  const { id } = useParams();
  const { register, handleSubmit, errors, control, formState } = useForm({
    mode: "onBlur",
  });

  const location = useLocation();
  const { company } = location.state;

  useEffect(() => {
    setHasAcceptedCreditor(!!location.state.hasAcceptedCreditor);
  }, [location]);

  const { touched } = formState;
  const onSubmit = (data) => {
    if (selectedErp.value === "sie4") {
      uploadSIE4File(data.file[0]);
      return;
    }
    const payload = selectedErp.getConfiguration(data);
    connectToErp(payload);
  };

  const connectToErp = async (payload) => {
    try {
      setIsLoading(true);
      await Axios.post(
        `${settings.apiBase}/api/configuration`,
        {
          ...payload,
          onboardingCreditor: creditor.clientIdAuth0,
          organizationId: company.id,
        },
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      history.replace(`/${id}/success`, {
        company,
        erpConnection: selectedErp.label,
      });
    } catch (error) {
      setErrorMessage(error.errorMessage);
      setIsLoading(false);
    }
  };

  const uploadSIE4File = async (file) => {
    setIsLoading(true);
    try {
      const uploadRequestResponse = await Axios.post(
        `${settings.apiBase}/api/upload/${company.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      const uploadRequest = uploadRequestResponse.data;
      await Axios.put(
        uploadRequest.requestLink,
        file,
        {
          headers: {
            "x-ms-blob-type": "BlockBlob",
          },
        }
      );
      await Axios.put(
        `${settings.apiBase}/api/upload`,
        {
          ...uploadRequest,
          companyPublicId: company.id,
          onboardingCreditor: creditor.clientIdAuth0,
        },
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      history.replace(`/${id}/success`, {
        company,
        erpConnection: "SIE4",
      });
    } catch (error) {
      setErrorMessage(error.errorMessage);
      setIsLoading(false);
    }
  };

  const onSelectErp = (erp) => {
    const selectedErp = erps.find((x) => x.value === erp.value);
    if (!selectedErp) return;
    setHasCheckedAccept(false)
    setSelectedErp(selectedErp);
    setErrorMessage("");
  };

  const Component = selectedErp ? selectedErp.component : null;

  const InformationModalBody = () => {
    return (
      <div className="modal-body"
      dangerouslySetInnerHTML={{
        __html: applicationTexts.chooseErpInformationModalText
          .replace("{creditorName}", creditor.creditorName),
      }}
      ></div>
    );
  };

  return (
    <ContainerWithSpinner isLoading={isLoading}>
      <div className="view connect-erp-view">
        <h2 className="header view-header h2">{applicationTexts.connectErpViewHeaderText}
          <ModalInfoButton
            modalHeader=""
            modalBody={<InformationModalBody />}
          >
            i
          </ModalInfoButton>
        </h2>
      {company && <h3 className="header h3">{company.name}</h3>}
      <DropdownComponent
        options={dropdownOptions(creditor.extractors, erps)}
        selectedItem={selectedErp}
        handleSelect={onSelectErp}
        dropdownPlaceholder={applicationTexts.erpDropdownPlaceholder}
        noOptionsMessage={() => applicationTexts.noAvailableErpsMessage}
      />
      <div className="container erp-container">
      {errorMessage && (
        <div className="message error-message">
          {errorMessage}
        </div>
      )}
        {selectedErp && (
            <form className="form">
              <Component
                touched={touched}
                register={register}
                submit={handleSubmit(onSubmit)}
                errors={errors}
                control={control}
                creditor={creditor}
                setHasCheckedAccept={setHasCheckedAccept}
                hasAcceptedCreditor={hasAcceptedCreditor}
                hasCheckedAccept={hasCheckedAccept}
              >
                {!hasAcceptedCreditor && (
                  <CheckboxComponent
                    errors={errors}
                    checkboxLabel={creditorAgreementText(creditor)}
                    control={control}
                    creditor={creditor}
                    hasAcceptedCreditor={hasAcceptedCreditor}
                    setHasAcceptedCreditor={setHasAcceptedCreditor}
                    hasCheckedAccept={hasCheckedAccept}
                    setHasCheckedAccept={setHasCheckedAccept}

                  />
                )}
              </Component>
            </form>
        )}
        {!selectedErp && (
          <p className="paragraph">
            {applicationTexts.chooseErpDescriptionText}
          </p>
        )}
      </div>
      <>
        {company.companyPermissions.includes("manageUsers") && !company.hasActiveInvitation && (
          <p className="paragraph">
            {applicationTexts.inviteConsentViewDescriptionText}
            <Link
              className="link to-invite-view-link"
              to={{ pathname: `/${id}/invite`, state: { company } }}
              replace
            >
              {applicationTexts.toInviteViewLinkText}
            </Link>
          </p>
        )}
        {company.hasActiveInvitation && (
          <div className="message success-message"
            dangerouslySetInnerHTML={{
              __html: applicationTexts.connectERPViewActiveInvitation,
            }}
          />
        )}
        <div className="view-links">
        <Link 
          className="link to-consent-view-link"
          to={`/${id}/consent`} replace>
          {applicationTexts.toConsentViewLinkText}
        </Link>
        </div>
      </>
      <Footer 
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />
      </div>
    </ContainerWithSpinner>
  );
}

const dropdownOptions = (creditorErps, erps) => {
  const keys = creditorErps.map(({ key }) => key);
  return erps
    .filter((x) => !keys.length || keys.includes(x.value))
    .map(({ value, label }) => ({ value, label }));
};

const CheckboxComponent = ({
  checkboxLabel,
  errors,
  control,
  style,
  creditor,
  setHasCheckedAccept
}) => {
  const _checkboxOnChange = (value) => {
    setHasCheckedAccept(value[0])
    return value[0];
  }
  return (
    <>
      {errors.terms &&
      <div className="message error-message checkbox-error-message">
        {errors.terms.message}
      </div>
      }
      <Controller
        as={<Checkbox />}
        label={checkboxLabel}
        style={style}
        type="checkbox"
        name="terms"
        valueName="checked"
        control={control}
        rules={{
          required: applicationTexts.creditorTermsNotAccepted.replace(
            "{creditorName}",
            creditor.creditorName
          ),
        }}
        onChange={_checkboxOnChange } 
      />

    </>
  );
};

