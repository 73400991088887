const prodConfig = ({
  apiBase: "https://app.mynumbers.se",
  visma: {
    authHost: "https://identity.vismaonline.com/connect/authorize",
    clientId: "exopensystems",
    redirectUri: "https://onboarding.mynumbers.se/connect.html",
    scopes: [
      "ea:accounting",
      "ea:api",
      "ea:purchase",
      "ea:sales",
      "offline_access",
    ],
  },
  fortnox: {
    authHost: "https://apps.fortnox.se/oauth-v1/auth",
    clientId: "n5ssiGFTu3TQ",
    redirectUri: "https://app.mynumbers.se/fortnoxRedirect",
    destinationUri: "/fortnoxActivation",
  },
  bankId: {
    showPersonalNumberInput: false
  },
  mode: "production",
  routePath: "/",
});

export default prodConfig;
