import React from "react";
import Modal from "./Modal";
import useModal from "../hooks/useModal";
export default function ModalInfoButton({
  modalHeader,
  style,
  children,
  modalBody,
}) {
  const { isShowing, toggle } = useModal();
  return (
    <>
      <button className="button info-button"
        style={style}
        onClick={(e) => {
          toggle();
          e.preventDefault();
        }}
      >
        {children}
      </button>
      <Modal isShowing={isShowing} hide={toggle} headerText={modalHeader}>
        {modalBody}
      </Modal>
    </>
  );
}
