import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import Axios from "axios";
import settings from "../settings/index";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import Footer from "../components/Footer";
import useModal from "../hooks/useModal";
import ModalInfoButton from "../components/ModalInfoButton";
import ToggleSwitch from "../components/ToggleSwitch";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";

const hasAcceptedCreditor = async (companyId, creditor, bearer) => {
  const statusRes = await Axios.get(`${settings.apiBase}/api/onboarding/${companyId}`, {
    headers: {
      Authorization: `Bearer ${bearer}`,
    },
  });
  return statusRes.data?.find(o => o.id === creditor)?.value === true;
}

const hasActiveInvitation = async (companyId, bearer) => {
  const invitationRes = await Axios.get(`${settings.apiBase}/api/aspnetusers/invitation/${companyId}`, {
    headers: {
      Authorization: `Bearer ${bearer}`,
    },
  });
  return !!invitationRes.data?.find(i => !i.used && new Date(i.expirationTime) > new Date());
}

export default function ConsentView({ creditor, customerInformation, isLoggedIn, logout }) {
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { showOtherCreditors } = creditor;
  const { id } = useParams();
  const location = useLocation();

  const getStatus = async () => {
    try {
      setIsLoading(true);
      const statusRes = await Axios.get(`${settings.apiBase}/api/companies`, {
        headers: {
          Authorization: `Bearer ${customerInformation.accessToken}`,
        },
      });
      const companiesResult = await Promise.all(statusRes.data.map(async c => {
        const acc = c.companyPermissions.includes("manageOnboarding") &&
          hasAcceptedCreditor(c.id, creditor.clientIdAuth0, customerInformation.accessToken);
        const inv = c.companyPermissions.includes("manageUsers") &&
          hasActiveInvitation(c.id, customerInformation.accessToken);
        return {
          ...c,
          hasAcceptedCreditor: await acc,
          hasActiveInvitation: await inv,
        }
      }));
      setCompanies(companiesResult);
      if (!companiesResult.length) {
        history.replace(`/${id}/connect-company`, { hasCompanies: false });
        return;
      }
      const redirectToConnectERP =
        location.state && location.state.redirectToConnectERP;
      if (
        companiesResult.length === 1 &&
        !companiesResult[0].extractorType &&
        redirectToConnectERP
      ) {
        history.replace(`/${id}/connect-erp`, {
          company: companiesResult[0],
          hasAcceptedCreditor: true,
        });
        return;
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(applicationTexts.couldNotFetchCompanies);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      getStatus();
      if (location.state && location.state.successMessage) {
        setSuccessMessage(location.state.successMessage);
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleConsentConfirmation = async (company) => {
    setIsLoading(true);
    const data = [{
      id: creditor.clientIdAuth0,
      value: !company.hasAcceptedCreditor,
    }];
    try {
      await Axios.put(
        `${settings.apiBase}/api/onboarding/${company.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      getStatus();
      const message = company.hasAcceptedCreditor
        ? applicationTexts.removedCreditorConfirmationText.replace(
            "{creditorName}",
            creditor.creditorName
          )
        : applicationTexts.addedCreditorConfirmationText.replace(
            "{creditorName}",
            creditor.creditorName
          );
      setSuccessMessage(message);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(applicationTexts.unknownError);
    }
  };

  const getStatusInformation = (company) => {
      return (
        <div>

          {!company.extractorType && (
          <div className="message warning-message icon">{applicationTexts.waitingForERPStatusMessage}</div>
          )}

          {company.companyPermissions.includes("manageUsers") &&
            company.hasActiveInvitation &&
            <div className="message success-message icon">{applicationTexts.hasActiveInvitationText}</div>
          }

          {company.extractorType && (
          <div className="message success-message icon">{applicationTexts.dataAccessThroughInfoText} {company.extractorType}</div>
          )}

          {(company.companyPermissions.includes("manageErpConnections") && !company.extractorType) && (
            <p className="paragraph">
              <Link
              className="link to-connect-erp-view-link"
              to={{
                pathname: `/${id}/connect-erp`,
                state: {
                  company,
                  hasAcceptedCreditor: company.hasAcceptedCreditor,
                },
              }}
              replace
            >
            {applicationTexts.toConnectErpViewLinkText}
            </Link>
            </p>
          )}
          <div className="links company-links">
          {
          (company.companyPermissions.includes("manageOnboarding") && showOtherCreditors && company.extractorType) && (
              <Link
              className="link to-consent-admin-view-link"
              to={{
                pathname: `/${id}/consent-admin/${company.id}`,
                state: {
                  company,
                  hasAcceptedCreditor: company.hasAcceptedCreditor,
                },
              }}
              replace
            >
            {applicationTexts.toConsentAdminLinkText}
            </Link>
          )
          }
          {(company.companyPermissions.includes("manageErpConnections") && company.extractorType === "SIE4") && (
            <Link
              className="link to-upload-file-view-link"
              to={{
                pathname: `/${id}/upload-file`,
                state: {
                  company,
                  hasAcceptedCreditor: company.hasAcceptedCreditor,
                },
              }}
              replace
            >
              {applicationTexts.uploadAdditionalFileLinkText}
            </Link>
          )}
          </div>
          {(company.extractorType && company.companyPermissions.includes("manageOnboarding") && !company.hasAcceptedCreditor && !showOtherCreditors) && (
            <p className="paragraph">{applicationTexts.useToggleToGiveConsentInfoText}</p>
          )}
        </div>
      );
  };

  const InformationModalBody = () => {
    return (
      <div className="modal-body">
        <p
         className="paragraph"
        dangerouslySetInnerHTML={{
          __html: applicationTexts.consentViewInformationModalText.replace(
            /\{creditorName}/g,
            creditor.creditorName
          ),
        }}
      ></p></div>
    );
  };
  return (
    <ContainerWithSpinner isLoading={isLoading}>
      <div className="view consent-view">
        <h2 className="header view-header h2">{applicationTexts.consentViewHeaderText}
        <ModalInfoButton
          modalHeader=""
          modalBody={<InformationModalBody />}
        >
          i
        </ModalInfoButton>
        </h2>
      {errorMessage && <div className="message error-message icon">{errorMessage}</div>}
      {successMessage && <div className="message success-message icon">{successMessage}</div>}
      <div className="container companies-container">
        {companies.map((company, index) => (
            <div key={index} className="company-container">
              <div className="company-header">
                {company.extractorType &&
                  (company.companyPermissions.includes("manageOnboarding") && !showOtherCreditors) && (
                    <ToggleConsentButton
                      company={company}
                      creditor={creditor}
                      data-tip
                      data-for="consent"
                      clearSuccessMessage={() => {
                        setSuccessMessage("");
                      }}
                      onConfirm={(company) => {
                        handleConsentConfirmation(company);
                      }}
                    />
                  )}
                <h3 className="header h3 company-name">
                  {company.name} {company.organizationNumber.slice(0, 6) + "-" + company.organizationNumber.slice(6)}
                </h3>
              </div>
              <div className="container company-status-container">
                {getStatusInformation(company)}
              </div>
            </div>
          )
        )}
      </div>
      <div className="view-links">
      <Link
        className="link to-connect-company-view-link"
        to={{
          pathname: `/${id}/connect-company`,
          state: { hasCompanies: true },
        }}
        replace
      >
        {applicationTexts.toCreateCompanyViewLinkText}
      </Link>
      </div>
      <Footer 
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />
      </div>
    </ContainerWithSpinner>
  );
}

const ToggleConsentButton = function ({
  creditor,
  company,
  onConfirm,
  clearSuccessMessage,
}) {
  const { isShowing, toggle } = useModal();
  const headerText = company.hasAcceptedCreditor
    ? applicationTexts.removeCreditorConnectionModalHeader.replace(
        "{creditorName}",
        creditor.creditorName
      )
    : applicationTexts.confirmCreditorConnectionModalHeader.replace(
        "{creditorName}",
        creditor.creditorName
      );
  const BodyText = () => {
    if (company.hasAcceptedCreditor) {
      if (creditor.disableAlertContent) {
        return creditor.disableAlertContent;
      }
      return (
        <>
          {applicationTexts.removeCreditorDefaultModalBodyText.replace(
            "{creditorName}",
            creditor.creditorName
          )}
        </>
      );
    }
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: applicationTexts.confirmCreditorConnectionModalBodyText
              .replace("{creditorName}", creditor.creditorName)
              .replace("{companyName}", company.name)
              .replace("{br}", "</br></br>"),
          }}
        />
        <ul>
          {creditor.permissions?.map(p => (
            <li key={p}>{applicationTexts["creditorPermissions."+p]}</li>
          ))}
        </ul>
      </>
    );
  };
  const FooterButtons = () => {
    if (company.hasAcceptedCreditor) {
      return (
        <>
          <button className="button modal-cancel-button" onClick={toggle}>
            {applicationTexts.cancelButtonText}
          <div className="button-icon"></div>

          </button>
          <button
            className="button modal-confirm-remove-button"
            onClick={() => {
              toggle();
              onConfirm(company);
            }}
          >
            {applicationTexts.removeCreditorConnectionButtonText}
          <div className="button-icon"></div>

          </button>
        </>
      );
    }
    return (
      <>
        <button className="button modal-cancel-button" onClick={toggle}>
          {applicationTexts.cancelButtonText}
          <div className="button-icon"></div>
        </button>
        <button 
          className="button modal-confirm-connect-button"
          onClick={() => {
            toggle();
            onConfirm(company);
          }}
        >
          {applicationTexts.confirmCreditorConnectionButtonText}
          <div className="button-icon"></div>
        </button>
      </>
    );
  };
  return (
    <>
      <ToggleSwitch
        onClick={() => {
          clearSuccessMessage();
          toggle();
        }}
        checked={company.hasAcceptedCreditor}
      />
      <Modal isShowing={isShowing} hide={toggle} headerText={headerText}>
        <>
          <div className="modal-body">
            <BodyText />
          </div>
          <div className="modal-footer">
            <FooterButtons />
          </div>
        </>
      </Modal>
    </>
  );
};
