import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import createBrowserHistory from "./history";

const appKey = process.env.REACT_APP_APP_INSIGHTS_KEY || "";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: appKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: createBrowserHistory },
    },
  },
});
if (appKey) {
  ai.loadAppInsights();
}

const aiTracking = (Component) =>
appKey ? withAITracking(reactPlugin, Component) : () => {};

export default aiTracking;
export const appInsights = ai.appInsights;
