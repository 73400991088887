import React from "react";
import Footer from "../components/Footer";
import applicationTexts from "../applicationTexts.json";

export default function FornoxLandingView() {
  return (
    <div className="view fortnox-landing-view">
        {applicationTexts.fortnoxActivationViewText}
      <Footer />
    </div>
  );
}
