import React, { useState, useRef } from "react";
import settings from "../../settings/index";
import { getQueryObject } from "../../helper";
import logo from "../../images/visma.png";
import ModalInfoButton from "../ModalInfoButton";
import applicationTexts from "../../applicationTexts.json";

export default function VismaComponent({ children, submit, register, hasCheckedAccept, hasAcceptedCreditor }) {
  let loginWindow;
  let timer;
  const [_code, setCode] = useState("");
  const submitButtonRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    submit();
    if (!_code && (hasCheckedAccept || hasAcceptedCreditor)) {
      initiateVismaLogin();
    }
  };

  const initiateVismaLogin = () => {
    if (!window || !window.open || typeof setInterval !== "function") {
      return;
    }
    const authUrl = createAuthUrl();
    let loginWindowWidth = window.screen.width;
    let loginWindowHeight = window.screen.height;
    if (loginWindowWidth > 1024) {
      loginWindowWidth = 1024;
    } 
    if (loginWindowHeight > 768) {
      loginWindowHeight = 768;
    } 
    loginWindow = window.open(authUrl, "myWindow", "width=" + loginWindowWidth + ",height=" + loginWindowHeight);
    timer = setInterval(checkIfComplete, 1000);
  };

  const checkIfComplete = () => {
    let href;
    try {
      href = loginWindow.location.href; // eslint-disable-line prefer-destructuring
    } catch (e) {
      return;
    }
    if (href && href.startsWith(settings.visma.redirectUri)) {
      clearInterval(timer);
      const query = loginWindow.location.search;
      loginWindow.close();
      loginWindow = null;
      const { code, error } = getQueryObject(query);
      if (error) {
        return;
      }
      setCode(code);
      submitButtonRef.current.click();
    } else if (loginWindow.closed) {
      clearInterval(timer);
    }
  };

  return (
    <div className="container erp-container">
      <div className="erp-header">
      <img className="erp-logo" src={logo} alt="logo" />
        <ModalInfoButton
          modalBody={
            <span
              dangerouslySetInnerHTML={{
                __html: applicationTexts.vismaInformationModalText,
              }}
            />
          }
        >
          i
        </ModalInfoButton>
      </div>
      <div className="input-container erp-input-container">
        <input
          className="input erp-input"
          type="hidden"
          name="code"
          ref={register({
            required: true,
          })}
          value={_code}
        />
        {children}
        <button 
          className="button erp-submit-button" 
          ref={submitButtonRef} 
          onClick={handleClick}
          disabled={!hasCheckedAccept && !hasAcceptedCreditor}>
          {applicationTexts.connectCompanyToErpButtonText}
          <div className="button-icon"></div>
        </button>
      </div>
    </div>
  );
}

const createAuthUrl = () => {
  const uid = `s_${new Date().valueOf().toString()}`;
  const scopes = settings.visma.scopes.join("+");
  const redirect = encodeURIComponent(settings.visma.redirectUri);
  const url = `${settings.visma.authHost}?client_id=${settings.visma.clientId}&redirect_uri=${redirect}&state=${uid}&scope=${scopes}&response_type=code`;
  return url;
};
