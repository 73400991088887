import React, { useState, useEffect } from "react";
import settings from "../settings/index";
import Footer from "../components/Footer";
import Axios from "axios";
import { useHistory, useParams, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ModalInfoButton from "../components/ModalInfoButton";
import ContainerWithSpinner from "../components/ContainerWithSpinner";
import applicationTexts from "../applicationTexts.json";
import SIE4Component from "../components/connectErp/SIE4Component";


export default function ConnectErpView({
  customerInformation,
  authenticationInformation,
  creditor,
  isLoggedIn, 
  logout
}) {

  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { register, handleSubmit, errors, control, formState } = useForm({
    mode: "onBlur",
  });

  const [logData, setLogData] = useState([]);
  const location = useLocation();
  const { company } = location.state;
  const { touched } = formState;
  const onSubmit = (data) => {
      uploadSIE4File(data.file[0]);
      return;
  };

  useEffect(() => {
    getSieLog(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getSieLog = async () => {
    try {
      setIsLoading(true);
      const res = await Axios.get(
        `${settings.apiBase}/api/upload/getsielog/${company.id}`,
        { headers: { Authorization: "Bearer " + authenticationInformation.authenticationToken.accessToken } }
      );
      if (res.data) {
        cleanAndSortLog(res.data)
      } 
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
    }
  };

  const cleanAndSortLog = (log) => {
    const uniqueYears = Array.from(new Set(log.map(a => a.fiscalYearStartDate))).map(fiscalYearStartDate => {
      return log.find(a => a.fiscalYearStartDate === fiscalYearStartDate)
    })
    uniqueYears.sort((a, b) => (a.fiscalYearStartDate > b.fiscalYearStartDate) ? -1 : 1)
    setLogData(uniqueYears);
  }

  const formatDate = (apiDate) => {
    const nicelyFormattedDate = apiDate.substring(0,10);
    return nicelyFormattedDate;
  }

  const uploadSIE4File = async (file) => {
    setIsLoading(true);
    try {
      const uploadRequestResponse = await Axios.post(
        `${settings.apiBase}/api/upload/${company.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      const uploadRequest = uploadRequestResponse.data;
      await Axios.put(
        uploadRequest.requestLink,
        file,
        {
          headers: {
            "x-ms-blob-type": "BlockBlob",
          },
        }
      );
      await Axios.put(
        `${settings.apiBase}/api/upload`,
        {
          ...uploadRequest,
          companyPublicId: company.id,
          onboardingCreditor: creditor.clientIdAuth0,
        },
        {
          headers: {
            Authorization: `Bearer ${customerInformation.accessToken}`,
          },
        }
      );
      history.replace(`/${id}/success`, {
        company,
        erpConnection: "SIE4",
      });
    } catch (error) {
      setErrorMessage(error.errorMessage);
      setIsLoading(false);
    }
  };



  return (
    <ContainerWithSpinner isLoading={isLoading}>
      <div className="view file-upload-view">
      <h2 className="header view-header h2">{applicationTexts.uploadAdditionalFileHeader} 
        <ModalInfoButton
          modalBody={
            <span
              dangerouslySetInnerHTML={{
                __html: applicationTexts.sie4InformationModalText,
              }}
            />
          }
        >
          i
        </ModalInfoButton>
        </h2>
      {company && 
        <p className="paragraph">
          {applicationTexts.uploadAndConnectAdditionalFileText.replace("{companyName}",company.name)} 
          {(logData && Array.isArray(logData)) && 
            (<span>{applicationTexts.seeUploadedFilesInListText}</span>) 
          }
        </p>
      }
      {(logData && Array.isArray(logData)) &&
        <div className="container uploaded-files-container">
          <h3 className="h3">Uppladdade räkenskapsår</h3>
          <ul className="ul list">
            { logData.map((data, index) => (
              <li className="li list-item uploaded-file-list-item" key={"uploadedYear"+index}>
                {formatDate(data.fiscalYearStartDate)} - {formatDate(data.fiscalYearEndDate)}
              </li>
            ))}
          </ul> 
        </div>
      }
      <div className="container file-upload-container">
        <form className="form">
        {errorMessage && (
          <div className="message error-message">
            {errorMessage}
          </div>
        )}
          <SIE4Component
            touched={touched}
            register={register}
            submit={handleSubmit(onSubmit)}
            errors={errors}
            control={control}
          >
          </SIE4Component>
        </form>
      </div>
      <>
        <div className="view-links">
        <Link 
          className="link to-consent-view-link"
          to={`/${id}/consent`} replace>
          {applicationTexts.toConsentViewLinkText}
        </Link>
        </div>
      </>
      <Footer 
        isLoggedIn={isLoggedIn}
        logout={logout}
        creditor={creditor}
      />
      </div>
    </ContainerWithSpinner>
  );
}
