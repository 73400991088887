import React from "react";

const Spinner = (props) => (
  <svg className="spinner" {...props} viewBox="0 0 50 50" role="status">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"

    />
  </svg>
);

export default Spinner;
