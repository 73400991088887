import React, { useState, useEffect } from "react";
import "./App.css";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import HomeView from "./views/HomeView";
import ErrorView from "./views/ErrorView";
import ConnectErpView from "./views/ConnectErpView";
import SuccessfullyConnectedView from "./views/SuccessfullyConnectedView";
import ConsentView from "./views/ConsentView";
import ConsentAdminView from "./views/ConsentAdminView";
import FortnoxActivationView from "./views/FortnoxActivationView";
import createBrowserHistory from "./history";
import httpService from "./interceptors";
import withAppInsights from "./AppInsights";
import useCookie from "./hooks/useCookie";
import AuthenticationView from "./views/AuthenticationView";
import ConnectCompanyView from "./views/ConnectCompanyView";
import FileUploadView from "./views/FileUploadView";
import UserRegisterView from "./views/UserRegisterView";
import axios from "axios";
import InviteView from "./views/InviteView";


const history = createBrowserHistory;

const useAppInsights = process.env.REACT_APP_APP_INSIGHTS_KEY;
function App() {
  const [creditor, setCreditor] = useState(null);
  // prettier-ignore
  const [authenticationInformation, setAuthenticationInformation] = useState(null);
  const [customerInformation, setCustomerInformation] = useState(null);
  
  const hasCreditor = () => !!creditor;
  const hasAuthenticated = () =>
    creditor && (authenticationInformation || persistLogin);
  const isLoggedIn = () => creditor && customerInformation;
  const [userInfo, setUserInfo, clearCookie] = useCookie("userInfo", null);
  useEffect(() => {
    const requestInterceptor = httpService.requestInterceptor(creditor);
    const responseInterceptor = httpService.responseInterceptor(
      setCustomerInformation,
      clearCookie,
      history,
      creditor
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [creditor,clearCookie]);

  const persistLogin =
    process.env.REACT_APP_PERSIST_LOGIN &&
    process.env.REACT_APP_PERSIST_LOGIN === "true";

  const _setCreditor = (_creditor, id) => {
    setCreditor(_creditor);
    if (_creditor.styleUrl) {
      const style = document.createElement("link");
      style.href = _creditor.styleUrl;
      style.rel = "stylesheet";
      style.async = true;
      document.head.appendChild(style);
    }
    if (persistLogin && userInfo) {
      setCustomerInformation(userInfo);
      history.replace(`/${_creditor.creditorGuid}/consent`, {
        redirectToConnectERP: true,
      });
    } else {
      history.replace(`/${id}/authenticate`);
    }
  };

  const logout = () => {
    setCustomerInformation(null);
    if (persistLogin) {
      clearCookie();
    }
  };

  const _setCustomerInformation = (_customerInformation) => {
    setCustomerInformation(_customerInformation);
    history.replace(`/${creditor.creditorGuid}/consent`, {
      redirectToConnectERP: true,
    });
  };

  const _setAuthenticationInformation = (authInfo) => {
    const { creditorGuid: id } = creditor;
    setAuthenticationInformation(authInfo);
    if (authInfo.status === "register") {
      history.replace(`/${id}/register`);
    } else if (authInfo.status === "login") {
      setCustomerInformation(authInfo.authenticationToken);
      if (persistLogin && !userInfo) {
        setUserInfo(authInfo.authenticationToken);
      }
      history.replace(`/${id}/consent`);
    }
  };

  return (
    <>
      <div className="onboarding-container">
          <Router history={history}>
            <Switch>
            <CustomRoute
                path="/fortnoxActivation"
                render={() => (
                  <FortnoxActivationView />
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/authenticate"
                validator={hasCreditor}
                redirect="/:id"
                render={() => (
                  <AuthenticationView
                    creditor={creditor}
                    setAuthenticationInformation={_setAuthenticationInformation}
                  />
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/register"
                validator={hasAuthenticated}
                redirect="/:id"
                render={() => (
                  <UserRegisterView
                    creditor={creditor}
                    setCustomerInformation={_setCustomerInformation}
                    authenticationInformation={authenticationInformation}
                  />
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/connect-erp"
                validator={isLoggedIn}
                redirect={"/:id/authenticate"}
                render={() => (
                  <ConnectErpView
                    customerInformation={customerInformation}
                    creditor={creditor}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                  ></ConnectErpView>
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/upload-file"
                validator={isLoggedIn}
                redirect={"/:id/authenticate"}
                render={() => (
                  <FileUploadView
                    customerInformation={customerInformation}
                    authenticationInformation={authenticationInformation}
                    creditor={creditor}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                  ></FileUploadView>
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/invite"
                validator={isLoggedIn}
                redirect={"/:id/authenticate"}
                render={() => (
                  <InviteView
                    creditor={creditor}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                    customerInformation={customerInformation}
                  />
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/success"
                validator={isLoggedIn}
                render={() => (
                  <SuccessfullyConnectedView
                  creditor={creditor}
                  isLoggedIn={isLoggedIn}
                  logout={logout}                  
                  />
                  )}
                >
              </CustomRoute>
              <CustomRoute
                path="/:id/consent"
                validator={isLoggedIn}
                redirect={"/:id/authenticate"}
                render={() => (
                  <ConsentView
                    creditor={creditor}
                    customerInformation={customerInformation}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                  />
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/consent-admin/:selectedCompanyId"
                validator={isLoggedIn}
                redirect={"/:id/authenticate"}
                render={() => (
                  <ConsentAdminView
                    creditor={creditor}
                    customerInformation={customerInformation}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                  />
                )}
              ></CustomRoute>
              <CustomRoute
                path="/:id/connect-company"
                validator={hasAuthenticated}
                redirect={"/:id/authenticate"}
                render={() => (
                  <ConnectCompanyView
                    creditor={creditor}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                    customerInformation={customerInformation}
                    authenticationInformation={authenticationInformation}
                  />
                )}
              ></CustomRoute>
              <Route path="/error">
                <ErrorView />
              </Route>
              <Route path="/:id">
                <HomeView setCreditor={_setCreditor} />
              </Route>
            </Switch>
          </Router>

      </div>
    </>
  );
}
let app = App;
if (useAppInsights) {
  app = withAppInsights(App);
}
export default app;

const CustomRoute = (props) => {
  if (props.validator && !props.validator()) {
    if (!props.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: { errorMessage: "Ett oväntat fel uppstod" },
          }}
        />
      );
    }
    const { id } = props.computedMatch.params;
    return <Redirect to={props.redirect.replace(":id", id)} />;
  }
  return <Route {...props} />;
};

