import React, { useRef } from "react";
import ReactDOM from "react-dom";

export default function Modal({ children, isShowing, hide, headerText }) {
  const hasOverlay =
    document &&
    document.querySelector &&
    document.querySelector("#app") &&
    document.querySelector("#app").querySelector(".modal-overlay");
  const modalContainerRef = useRef();
  const outsideClick = (e) => {
    if (
      modalContainerRef.current &&
      !modalContainerRef.current.contains(e.target)
    ) {
      hide();
    }
  };

  return isShowing
    ? ReactDOM.createPortal(
        <>
          {!hasOverlay && <div className="modal-overlay" />}
          <div className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            onClick={outsideClick}
          >
            <div 
              className="modal modal-container"
              ref={modalContainerRef}>
              <div className="modal-header">
              <div 
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  {" "}
                  <span aria-hidden="true">&times;</span>
                </div>
                <h3 className="header h3">{headerText}</h3>

              </div>
              {children}
            </div>
          </div>
        </>,
        document.querySelector("#app")
      )
    : null;
}

