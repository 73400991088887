import React from "react";
import FileUpload from "../FileUpload";
import { Controller } from "react-hook-form";
import applicationTexts from "../../applicationTexts.json";

export default function SIE4Component({ submit, errors, children, control }) {
  const handleClick = async (e) => {
    e.preventDefault();
    submit();
  };
  return (
    <div className="container erp-container">
      <div className="input-container erp-input-container file-upload-container">
      {errors.file &&
        <div className="message error-message">
          {errors.file.message}
        </div>
        }
        <label className="label file-upload-label sie4-file-upload-label">{applicationTexts.uploadFileFromHarddriveLabel}</label>
        <Controller
          as={<FileUpload />}
          type="file"
          name="file"
          accept=".se,.SE,.Se,.sE"
          control={control}
          onChange={(e) => {
            return e;
          }}
          rules={{
            required: applicationTexts.mandatoryField,
            validate: (files) => {
              if (files[0].name.split(".").pop().toLowerCase() !== "se") {
                return applicationTexts.sie4IncorrectFileExtensionErrorMessage;
              }
            },
          }}
        />
        {children}
        <button className="button erp-submit-button file-upload-submit-button" type="submit" onClick={handleClick}>
          {applicationTexts.connectCompanyToSIE4ButtonText}
          <div className="button-icon"></div>
        </button>
      </div>
    </div>
  );
}
