import axios from "axios";
import applicationTexts from "./applicationTexts.json";

const persistLogin =
  process.env.REACT_APP_PERSIST_LOGIN &&
  process.env.REACT_APP_PERSIST_LOGIN === "true";

const interceptor = ({
  requestInterceptor: (creditor) => {
    return axios.interceptors.request.use(
      (config) => {
        if (creditor) {
          config.headers.CreditorId = creditor.id;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  responseInterceptor: (
    setCustomerInformation,
    clearCookie,
    history,
    creditor
  ) => {
    return axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const id = creditor ? creditor.creditorGuid : null;
        if (error.response) {
          if (error.response.status === 401) {
            const requesturl = error && error.config && error.config.url;
            if (requesturl && !requesturl.includes("login")) {
              // This is run in a timeout so that the `Promise.reject` is handled before the history is replaced.
              // That way, we avoid "Tried to update unmounted component"-errors from React.
              setTimeout(() => {
                setCustomerInformation(null);
                if (persistLogin) {
                  clearCookie();
                }
                history.replace(`/${id}/authenticate`);
              });
            }
          }
          const errorResponseCode =
            error.response.data &&
            error.response.data &&
            error.response.data.split(";")
              ? error.response.data.split(";")[0]
              : null;
          const errorMessage = mapErrorMessage(
            errorResponseCode,
            error.request
          );
          return Promise.reject({
            errorMessage,
            ...error,
          });
        }
        return Promise.reject({
          errorMessage: applicationTexts.unknownError,
          ...error,
        });
      }
    );
  },
});

export default interceptor;

const mapErrorMessage = (errorResponse, request) => {
  let errorMessage;
  switch (errorResponse) {
    case "13": //conflict
      if (request.responseURL.includes("register")) {
        errorMessage =
          applicationTexts["errorCode13|EmailAddressAlreadyExists"];
      }
      break;
    case "2":
    case "3":
    case "4":
    case "5":
    case "6":
    case "7":
    case "8":
    case "9":
    case "10":
    case "12":
    case "16":
    case "17":
    case "21":
    case "22":
      const key = Object.keys(applicationTexts).find((x) =>
        x.includes(`errorCode${errorResponse}`)
      );

      errorMessage = applicationTexts[key];
      break;
    case "1": //internal error
    case "14": //general error
    case "15": //general error
    default:
      errorMessage = applicationTexts.unknownError;
      break;
  }
  return errorMessage;
};
